.text-blue {
    color: $blue;
}

.text-white {
    color: $white;
}

.text-center {
    text-align: center;
}

.text-gray {
    color: $gray;
}
.text-dark-gray {
    color: $darkGray;
}

.fw-extra {
    font-weight: 900;
}

.fw-bold {
    font-weight: 600;
}

.fw-normal {
    font-weight: normal;
}
