$white: #fff;
$black: #313131;
$yellow: #faa214;

$blue: #DE2C48;
$blueLight: #0089cf;

$gray: #7c7c7c;
$darkGray: #585858;
$grayDisabled: #babcbe;
