.side-menu {
    height: 100%;
    width: 200px;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: #fafafa;
    transition: 0.5s;

    .menu-items {
        list-style: none;
        margin-top: 70px;
        min-width: 150px;
        max-width: 250px;
        padding: 0;

        .app-store-image-img {
            height: 45px;
        }
        li {
            padding: 5px 10px !important;
            &:hover {
                background-color: #F8D5DA;
                border-radius: 10px;
                transition: 0.5s;

            }
        }
    }
    a {
        font-size: 23px;
        font-weight: 600;
        color: $blue;
        display: block;
        transition: 0.3s;
        text-decoration: none;
    }
}
