.footer {
    border-top: #de2c48 3px solid;
    margin-top: 80px;
    height: 250px;
    background-color: #fbeaec;
    padding: 0px 20px 20px 20px;

    .container {
        position: relative;
    }
    .logo-container {
        position: absolute;
        right: 10px;
        width: 100px;
        top: 40px;
    }

    .footer-content {
        color: #de2c48;
        text-align: right;
        width: 1000px;
        float: right;
        margin-top: 80px;

        .social {
            margin-bottom: 50px;

            .text {
                color: #de2c48;
                font-size: 20px;
                margin-bottom: 20px;
            }
        }

        .footer-navigation {
            ul {
                list-style: none;

                li > a {
                    color: #de2c48;
                    font-size: 20px;
                }
            }
        }
    }
}
