.contact-us-email-form {
    label {
        margin-top: 20px;
        font-weight: 500;
    }
    input[type="submit"] {
        margin-top: 20px;
        background-color: $blue;
        border-color: $blue;
        &:active {
            background-color: $blue;
            border-color: $blue;
        }
    }
}
