.page {
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    flex:1;
    height: 100vh;


    .page-header {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;

        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        border-bottom: 1px solid #00000022;
        box-shadow: 0px 4px 20px 0px #00000022;

        
        .logo-container {
            width: max(100px, min(120px, 20vw));
            padding-top: 4px;
            display: inline-block;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
        }
    }

    .page-content {
        display: flex;
        flex:1;
        flex-direction: column;
        background-color: $white;
        padding: 20px 20px;
        padding-top: 70px;
        align-items: center;
        .page-title {
            background-color: transparent;
            width: 100%;
        }

        .page-body {
            padding-top: 15px;
            max-width: 1296px;
            width: 100%;

        }
    }

    .menu-button {
        border: none;
        background: transparent;
    }

    .page-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
