.landing-page {
    .page-header {
        background-color: $blue;
        height: 550px !important;
        overflow: hidden;
        align-items: center;

        .logo-header {
            margin-bottom: 100px;
            width: 200px;
            img {
                width: 100%;
            }
        }

        .container {
            position: relative;
        }

        .landing-image-wrapper {
            position: absolute;
            top: -10%;
            right: -200px;

            width: 80%;
            min-width: 700px;
            img {
                width: 100%;
                height: auto;
            }

            // Size for desktop and larger screens
            @media (min-width: $desktop) {
                top: -30%;
                right: -200px;
                width: 80%;
                min-width: 700px;
            }

            // Dont show on mobile phones
            @media (max-width: $tablet) {
                display: none;
            }
        }
    }

    .decoration-title {
        position: relative;
        text-align: center;
        color: white;
        margin: auto;
        margin-bottom: 20px;

        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .company-logos {
        .company-logo {
            height: 50px;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50% 50%;
        }
    }
}

.about-us-section {
    max-width: 1300px;
    padding: 0 0;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 50px auto;

    &.section-container {
        padding: 0px;
        display: grid;

        grid-template-columns: 1fr 1fr;
        gap: 24px;
    }

    &.section-two-container {
        padding: 0px;
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        gap: 24px;
        img {
            margin: 0 auto;
        }
    }

    &.section-three-container {
        padding: 0px;
        display: grid;
        grid-template-columns: 1fr 4fr;
        gap: 24px;
        img {
            margin: 0 auto;
        }
    }

    @media (max-width: 900px) {
        &.section-container {
            grid-template-columns: 1fr;
        }

        &.section-two-container {
            grid-template-columns: 1fr;
            .phone-image:nth-child(3) {
                display: none;
            }
        }

        &.section-three-container {
            grid-template-columns: 1fr;
        }
    }

    & img {
        max-height: 500px;
        align-self: center;
    }
    & p {
        font-size: 1.1em;
        text-align: justify;
    }
}

.partner-logo-wrapper {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: 800px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 1000px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 1200px) {
        grid-template-columns: repeat(5, 1fr);
    }
}

.partner-logo {
    filter: grayscale(1);
    opacity: 0.6;
    margin: 0 auto;
    max-width: 120px;
}
