// Base
@import "base/base.scss";

// Pages
@import "pages/home_page";
@import "pages/hopar";

// Components
@import "components/email_form";
@import "components/page";
@import "components/offer";
@import "components/side-menu.scss";
@import "components/company_info.scss";
@import "components/footer";
@import "components/close_button.scss";
@import "components/reset_verify.scss";
@import "components/fan.scss";

html {
    background-color: transparent;
}
body {
    background-color: #fff;
    color: $black;
    font-family: "Livvic";
}
