.groups-page {
    background-color: white !important;
}
.about-us-section {
    max-width: 1300px;
    padding: 0 0;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 50px auto;

    &.group-section-one {
        padding: 0px;
        display: grid;

        grid-template-columns: 2fr 1fr;
        gap: 60px;
        font-size: 1.5em;
        justify-items: center;
        li {
            margin: 0;
            padding: 0;
            font-size: 25px;
        }
        h5 {
            font-size: 1.4em;
        }
    }

    &.group-section-two {
        padding: 0px;
        display: grid;

        grid-template-columns: 1fr 2fr;
        gap: 60px;
        font-size: 1.5em;
        justify-items: center;

        h5 {
            font-size: 1.5em;
        }
    }

    &.group-section-three {
        padding: 0px;
        display: grid;

        grid-template-columns: 1fr 2fr;
        gap: 60px;
        font-size: 1.5em;
        justify-items: center;

        h5 {
            font-size: 1.5em;
        }
    }

    &.group-section-four {
        padding: 0px;
        display: grid;

        grid-template-columns: 1fr 1fr;
        gap: 60px;
        font-size: 1.5em;
        justify-items: center;
        li {
            margin: 0;
            padding: 0;
            font-size: 25px;
        }
        h5 {
            font-size: 1.5em;
        }
    }

    &.section-two-container {
        padding: 0px;
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        gap: 24px;
        img {
            margin: 0 auto;
        }
    }

    &.section-three-container {
        padding: 0px;
        display: grid;
        grid-template-columns: 1fr 4fr;
        gap: 24px;
        img {
            margin: 0 auto;
        }
    }

    @media (max-width: 1290px) {
        &.group-section-four {
            grid-template-columns: 1fr;
        }
    }

    @media (max-width: 900px) {
        &.group-section-one {
            grid-template-columns: 1fr;
        }

        &.group-section-two {
            grid-template-columns: 1fr;
            img {
                display: none;
            }
        }
        &.group-section-three {
            grid-template-columns: 1fr;
        }

        &.section-two-container {
            grid-template-columns: 1fr;
            .phone-image:nth-child(3) {
                display: none;
            }
        }

        &.section-three-container {
            grid-template-columns: 1fr;
        }
    }

    & img {
        max-height: 500px;
        align-self: center;
    }
    & p {
        font-size: 25px;
        text-align: justify;
    }
}
