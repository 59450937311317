.fan {
    position: relative;
    margin: 0 0px;
    aspect-ratio: 1.2;
    max-height: 100%;
    max-width: 100%;
}

.fan-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: auto;
    height: 100%;
    border-radius: 20px;
}

.fan-image:nth-child(1) {
    z-index: 0;
    transform: scale(0.85) translate(15px, 0px);
    transform-origin: bottom left;
    animation: card1 1s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
}

.fan-image:nth-child(2) {
    z-index: 1;
    transform: scale(0.9) translate(15px, 0px);
    transform-origin: bottom left;
    animation: card2 1s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
}

.fan-image:nth-child(3) {
    z-index: 2;
    transform: scale(0.95);
    animation: card3 1s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
    box-shadow: 0px 1px 10px #0000001f;
    transform-origin: bottom;
}

.fan-image:nth-child(4) {
    z-index: 1;
    transform: scale(0.9) translate(-15px, 0px);
    transform-origin: bottom right;
    animation: card4 1s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
}

.fan-image:nth-child(5) {
    z-index: 0;
    transform: scale(0.85) translate(-15px, 0px);
    transform-origin: bottom right;
    animation: card5 1s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
}

@keyframes card1 {
    100% {
        transform: rotate(-25deg) scale(0.9);
        box-shadow: 0px 1px 10px #0000001f;
    }
}

@keyframes card2 {
    100% {
        transform: rotate(-15deg) scale(0.95);
        box-shadow: 0px 1px 10px #0000001f;
    }
}

@keyframes card3 {
    100% {
        transform: rotate(0deg) scale(1);
    }
}

@keyframes card4 {
    100% {
        transform: rotate(15deg) scale(0.95);
        box-shadow: 0px 1px 10px #0000001f;
    }
}

@keyframes card5 {
    100% {
        transform: rotate(25deg) scale(0.9);
        box-shadow: 0px 1px 10px #0000001f;
    }
}
