.title {
    font-weight: 600;
}

h1 {
    font-weight: 900;
}

h1,
h2,
h3,
h4,
h5,
h5,
p,
li {
    color: $black;
}

a {
    cursor: pointer;
}

/* vietnamese */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCr-x1S2hzjrlffC9M2knjsS_ulYHs.woff2)
        format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCr-x1S2hzjrlffC9M3knjsS_ulYHs.woff2)
        format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
        U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCr-x1S2hzjrlffC9M5knjsS_ul.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlffp8Iuul3DY_GtWEIJ.woff2)
        format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlffp8Iuu13DY_GtWEIJ.woff2)
        format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
        U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlffp8IutV3DY_GtWA.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlffw8Euul3DY_GtWEIJ.woff2)
        format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlffw8Euu13DY_GtWEIJ.woff2)
        format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
        U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlffw8EutV3DY_GtWA.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCp-x1S2hzjrlfXZ-M7mH_OScuk.woff2)
        format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCp-x1S2hzjrlfXZuM7mH_OScuk.woff2)
        format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
        U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCp-x1S2hzjrlfXaOM7mH_OSQ.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlffm8Auul3DY_GtWEIJ.woff2)
        format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlffm8Auu13DY_GtWEIJ.woff2)
        format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
        U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlffm8AutV3DY_GtWA.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlfft8cuul3DY_GtWEIJ.woff2)
        format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlfft8cuu13DY_GtWEIJ.woff2)
        format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
        U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlfft8cutV3DY_GtWA.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlff08Yuul3DY_GtWEIJ.woff2)
        format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlff08Yuu13DY_GtWEIJ.woff2)
        format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
        U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlff08YutV3DY_GtWA.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlff68Quul3DY_GtWEIJ.woff2)
        format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlff68Quu13DY_GtWEIJ.woff2)
        format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
        U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Livvic";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlff68QutV3DY_GtWA.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
