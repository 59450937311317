.company-info {
    .company-section {
        border-top: 1px #f1f2f3 solid;
        border-bottom: 1px #f1f2f3 solid;

        padding: 20px 0;

        .subtitle {
            margin-bottom: 0;
            font-weight: normal;
        }

        p {
            margin: 0;
            color: $gray;
        }
    }

    .contact-section {
        padding: 15px 0;
        border-bottom: 1px #f1f2f3 solid;

        span {
            font-size: 18px;
        }
    }

    .opening-hours {
        padding: 20px 0;

        .title {
            margin-bottom: 10px;
            color: $darkGray;
        }

        ul {
            list-style: none;
            padding: 0;
        }
    }
}
